import React from 'react';
import Styles from './boost.module.scss';
import { Layout } from '../../components';

const Boost = () => {
    return (
        <Layout title1="Boost" title2="Your Business" description="Boost Your Instagram Presence: Buy Followers & Likes Now" mainClass={Styles.boost} >
            
        </Layout>
    )
}

export default Boost